import React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../components/blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Commentsimg from '../images/comments.svg';
import ProfileImg from '../images/profile.svg';
import BlogImg from '../images/blogImg.png';
import Facebook from '../images/facebookBlue.png';
import Twitter from '../images/twitterBlue.png';
import Linkedin from '../images/linkedinBlue.png';
import MailBlue from '../images/mailBlue.png';
import { FacebookProvider, Comments } from 'react-facebook';
import FeaturedPosts from '../components/featuredPosts';
import CardsRowImg1 from '../images/cardsRowImg1Full.png';
import DavidImg from '../images/David.png';

class BradenWinstheSnowball extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  
  render(){
    return(
      <Layout>
        <div id="fb-root"></div>
          <div className={styles.landingSection}>
            <div className={styles.landingSectionSubDiv}>
              <text className={styles.blogHeader}>PropMe’s Peer-to-Peer Marketplace</text>
              <div className={styles.subtitle}>
                <div className={styles.profileCol2}>
                  <img className={styles.ProfileImg} src={ProfileImg} alt="landingimg" />
                  <text className={styles.profileTest}>By David Ealy</text>
                  <p>16 Jul, 2020</p>
                </div>
                <div className={styles.profileCol2} id={styles.profilecolId}>
                  <img className={styles.ProfileImg} src={Commentsimg} alt="landingimg" />
                  <text className={styles.profileTest}>0 Comments</text>
                </div>
              </div>
              <text className={styles.blogDesc}>
                <div className={styles.subblogDesc}>
                  <text>Overview</text>
                </div>
                  <p>Sports wagering in America received its wings on May 14, 2018 when the United States Supreme Court ruled that the Professional and Amateur Sports Protection Act (PASPA), the 1992 federal law that had prevented states from regulating sports betting if they had not already done so, was unconstitutional. Since then, many states have legalized sports betting, and casino operators (e.g. MGM, FanDuel, Caesars, DraftKings) are opening both online and physical sports books.</p>  

                  <p>In all cases, these online and physical sportsbooks, in addition to Daily Fantasy Sports, are geared to seasoned and experienced gamblers with little or no offerings that focus on the everyday fan, the female audience, or other wagering categories (e.g., entertainment awards). Play daily fantasy, bet a sportsbook, blah, blah, blah…</p>
                <div className={styles.subblogDesc}>
                  <text>Enter PropMe’s Peer-to-Peer Marketplace</text>
                </div>
                  <p>The concept is simple – I should be able to bet anyone on anything at any time. Now, how do we do that? We create a “marketplace” a one-stop shop that’s easy to use and allows a user to place a prop bet in the market for others to shop for a find.   As my co-founder Jake Padlow says, “the bet is no good unless there’s money on the wood.”</p>
                <div className={styles.subblogDesc}>
                  <text>Here’s the set-up –</text>
                </div>
                  <p>Alex is home and ready to watch a basketball game. He wants to bet during the game, so Alex enters our marketplace looking for a prop. He finds a prop, picks an amount to bet, then sends it directly to Mark for a one-on-one bet (as shown on the right image).</p>

                  <p>There are many variations on the P2P betting, but our focus is on three ways (as shown on the left image).</p>

                  <p>We use “prop bet” because a prop bet is binary, or there’s a definite winner and loser, and it allows for the prop to be settled at different times in an event.  This allow us to re-purpose the props for our other features like our pick’em contests.</p>
              </text>
              <img className={styles.blogImg} src={CardsRowImg1} alt="landingimg" />
              <text className={styles.blogDesc}>
                <div className={styles.subblogDesc}>
                  <text>Is this really a new concept?</text>
                </div>
                <p>Not 100%. But, this idea of peer-to-peer betting in a marketplace without a sportsbook to create the prop, validating the prop winner, and completely removing the need to pay a sportsbook is new.   The big players in the industry which were the first movers see and understand this and you’ll see them start to adopt this approach and technology soon. History shows us that first movers eventually give way to innovation, right?   And, the market is HUGE.</p>  
                <div className={styles.subblogDesc}>
                  <text>What are the challenges?</text>
                </div>
                <p>Legality - Gambling in the United States is now legal, but states are in control of the rollout (licensing).   New technology is always scary to the “old guard” and peer-to-peer has yet to gain a large foothold. It will in 2020 or 2021.</p> 

                <p>Navigation. Because the volume of users we’re expecting is high, we have a simple way to organize the props, once validated by the marketplace. We explain this organization method like this – when you go to a mall, all the vendors in the mall don’t have their products in one big pile. Rather, the shopper knows where in the mall to go to buy shoes, or the location of a specific store. Same idea of our props. Our initial offering of the marketplace will have storefronts, if you will. If you want NFL games, then you go to the NFL store. If you want NFL games and Sunday afternoon games, then go that section of the store.</p>
                <div className={styles.subblogDesc}>
                  <text>Why will this idea work?</text>
                </div>
                <p>Yes!   There’s always someone who is willing to play or challenge your prop.  Team loyalty and pride runs deep in all cultures, so offering an outlet to challenge your opponent for the day is an easy sell.</p>

                <p>Props are easy to understand. One drawback to the early daily fantasy sports applications is that you needed a depth of understanding in the sport. Everyone can play using props. It’s easy to create and accept a prop.</p>

                <p>In-game betting. PropMe and the prop marketplace is perfect for in-game betting. What if you see a trend in the game, it’s easy to quickly create a prop and place it in the store. With all the location-based services available, why couldn’t we identify users that are attending the same game? This is on our roadmap and this technology is ubiquitous.</p>

                <p>There is always an event. This isn’t dependent on one season, one sport or one event. We’ve already started working through an entertainment offerings and other games.</p>

                <p>Come join us, we’d love to have you! You can join by going to www.propmellc.com</p>
              </text>
              <img className={styles.DavidImg} src={DavidImg} alt="landingimg" />
              <div>
                <text className={styles.blogDesc}>David Ealy – Co- Founder</text>
              </div>
              <div className={styles.oldPostsLink}>
                <a className={styles.servicelinks} href="/blockchain"><text>{'<'}Older Post</text></a>
                <a className={styles.servicelinks}href="/braden-wins-the-snowball"><text>Newer Post{'>'}</text></a>
              </div>
              <div className={styles.BlogsocialLinksDiv}>
                <img className={styles.social} src={Facebook} alt="landingimg" />
                <img className={styles.social} src={Twitter} alt="landingimg" />
                <img className={styles.social} src={Linkedin} alt="landingimg" />
                <img className={styles.social} src={MailBlue} alt="landingimg" />
              </div>
              {/* <FacebookProvider appId="691895914788460">
                <Comments href="https://www.facebook.com/peerToPeerMarketingBlog" data-width="100vw"/>
              </FacebookProvider>
              <iframe src="https://www.facebook.com/plugins/comments.php?href=https://friendly-curran-11b640.netlify.app/propmes-peer-to-peer-marketplace/" scrolling="no" frameBorder="0" allowtransparency="true"></iframe>
              <div className="fb-comments" data-href="https://friendly-curran-11b640.netlify.app/propmes-peer-to-peer-marketplace" data-numposts="10"></div> */}
              <iframe name="f297624ad60196" width="500px" height="100px" data-testid="fb:comments Facebook Social Plugin" title="fb:comments Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/v2.0/plugins/comments.php?app_id=691895914788460&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df247eedd96682d4%26domain%3Dfriendly-curran-11b640.netlify.app%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ffriendly-curran-11b640.netlify.app%252Ffe72a2fa7cef6c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=714&amp;height=100&amp;href=https%3A%2F%2Fwww.facebook.com%2Fblah%2F&amp;locale=en_US&amp;numposts=6&amp;sdk=joey&amp;version=v2.0&amp;width=500" style={{border: 'none', visibility: 'visible', width: '500px', height: '458px'}} class=""></iframe>
              <div className={styles.featuredNewsDiv}>
                <text>Featured News</text>
              </div>
              </div>
            </div>
          <div>
          <FeaturedPosts/>
        </div>
      </Layout>
    )
  }
}

export default BradenWinstheSnowball