import React from 'react';
import * as styles from './blog.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CardsRowImg1 from '../images/cardsRowImg1.png';
import CardsRowImg2 from '../images/cardsRowImg2.png';
import CardsRowImg3 from '../images/cardsRowImg3.png';

class FeaturedPosts extends React.Component{
  constructor(props){
    super(props);
    this.state={

    }
  }
  render(){
    return (
      <div className="row">
        <div className="col-lg-4" id={styles.whatDivCol}>
          <div className={styles.cardsRow1Col}>
            <div className={styles.partners1}>
              <img className={styles.partners1Img} src={CardsRowImg1} alt="hugesuccess" />
              <div className={styles.contentsdiv}>
                <div className={styles.partners1headerMain}>
                  <text className={styles.partners1header}>PropMe’s Peer-to-Peer Marketplace</text>
                </div>
                <text className={styles.partnerDesc}><p>Overview. Sports wagering in America received its wings on May 14, 2018 when the United States Supreme… Court ruled that the Professional and Amateur Sports Protection Act (PASPA), the 1992 federal law that hadPeer-to-Peer Marketplace</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                <div className={styles.continueDiv}>
                  <a className={styles.servicelinks} href="/propmes-peer-to-peer-marketplace"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4" id={styles.whatDivCol}>
          <div className={styles.cardsRow1Col}>
            <div className={styles.partners1}>
              <img className={styles.partners1Img} src={CardsRowImg2} alt="hugesuccess" />
              <div className={styles.contentsdiv}>
                <div className={styles.partners1headerMain}>
                  <text className={styles.partners1header}>Braden Wins the Snowball</text>
                </div>
                <text className={styles.partnerDesc}><p>It was the most improbable finish of his career. It was wild and it was staggering, several times over. But if you’ve paid close attention, you could’ve seen this coming, because it was also methodical and surgical. It was Travis Braden showing how it’s done.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                <div className={styles.continueDiv}>
                <a className={styles.servicelinks} href="/braden-wins-the-snowball"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4" id={styles.whatDivCol}>
          <div className={styles.cardsRow1Col}>
            <div className={styles.partners1}>
              <img className={styles.partners1Img} src={CardsRowImg3} alt="hugesuccess" />
              <div className={styles.contentsdiv}>
                <div className={styles.partners1headerMain}>
                  <text className={styles.partners1header}>Braden Helps Promote Virtual Racing</text>
                </div>
                <text className={styles.partnerDesc}><p>MCMECHEN — Pro race car driver and Wheeling native Travis Braden grew up wishing that video game technology... would be more realistic — especially when it came to racing. That time has come.</p> <p>The Wheeling Wheelman rode his training, talent and grit to capture his biggest victory to date, taking the prestigious 2019 Snowball Derby Monday at the 5 Flags Speedway in Pensacola, Fla. It was Braden’s first time qualifying for the race, and even that was chancy, given the circumstances.</p> <p>But to WIN? That, my friends, was remarkable.</p> <p>To quickly recap: 1. Braden was wrecked in practice Friday, destroying the right side of the car. The crew replaced the entire suspension, got the ride ready and, despite no practice with “the new car,” Braden was able to qualify for the race. 2. He qualified 30th, which, on a short track with an old, slick, asphalt surface, put his car in the crosshairs for more beatin’ and bangin’ and perhaps another wreck. 3. He went a lap down on an early pit stop. 4. He was spun by another car in the last five laps.</p> <p>But to pull himself and Team Platinum together and make a run to the front after all the issues? Braden, just 25, relied on his experience and talent. When the car went a lap down and fell to 34th, the crew gambled and took on new tires before any of the other cars. This allowed him to maneuver, dart and dodge his way to sixth, and put himself into position to make a run for the win.</p> <p>Travis Braden is fast, and he’s smart. He’s got two degrees in engineering from West Virginia University — mechanical and aerospace — and knows his car inside and out.</p> <p>“I can build a car from the ground up,” he admitted. “A lot of the drivers can’t say that.”</p></text>
                <div className={styles.continueDiv}>
                <a className={styles.servicelinks} href="/braden-helps-promote-virtual-racing"><text className={styles.continuetext1}>CONTINUE READING{'>'}</text></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturedPosts